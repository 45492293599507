import React from 'react';
import './Results.css'

const Results = (props) => {
  const resultList = props.results.map((r,ix) => <li key={`${r}_${ix}`}>{`${r} (${r.length})`}</li>)

  return (
    <section className="results">
      <ul>
        {resultList}
      </ul>
    </section>
  )
}

export default Results;