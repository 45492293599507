import React, { Component } from 'react';
import './App.css';
import Results from './components/Results';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { chars: "", results: [] };
  }

  handleInputChange(e) {
    this.setState({ chars: e.target.value });
  }

  doSearch() {
    const word = this.state.chars.toLowerCase();
    console.log(word);
    fetch(`https://ordfinnaren.com/api/findwords/${word}`)
      .then((res) => {
        return res.json();
      }).then((json) => {
        console.log(json);
        this.setState((state, props) => ({ results: json }));
      }).catch((err) => {
        window.alert(err);
      })
  };

  keyPressed(e) {
    if (e.key === "Enter") {
      this.doSearch();
    }
  }

  render() {
    return (
      <div className={this.state.results.length === 0 ? "App" : "App show-results"}>
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <div className="logo"><span>ORD</span><span>FINN</span><span className="smaller">AREN</span></div>
          <div className="search">
            <input 
              id="search-field"
              type="text"
              placeholder="Bokstäver"
              value={this.state.chars}
              onKeyPress={this.keyPressed.bind(this)}
              onChange={this.handleInputChange.bind(this)} 
              autoComplete="off" />
            <button id="search-button" onClick={(e) => this.doSearch()} className={this.state.chars === '' ? "hidden" : ""}>
              <span>SÖK</span>
            </button>
          </div>
        </header>
        { this.state.results.length > 0 && <Results results={this.state.results}></Results> }
      </div>
    );  
  }
}

export default App;
